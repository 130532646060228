<template>
  <b-row>
    <!-- Add Contact -->

    <b-modal id="modal-add" centered title="Add Contact" hide-footer>
      <validation-observer ref="formContact" #default="{ invalid }">
        <b-form @submit.prevent="addContact">
          <b-row>
            <!-- username -->
            <b-col cols="12">
              <!--Status-->
              <b-form-group label="Title" label-for="h-Status" label-cols-md="2" class="">
                <b-form-group label-for="h-Status" class="">
                  <b-form-select v-model="selected" :options="options" />
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Full Name" label-for="h-company">
                <validation-provider rules="required" name="Full Name" #default="{ errors }">
                  <b-form-input
                    id="h-full"
                    placeholder="Full Name"
                    v-model="contact.otherName"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- email -->
            <b-col cols="12">
           
                <b-form-group label="Email" label-for="d-email">
                  <b-form-input
                    id="d-email"
                    type="email"
                    placeholder="Email"
                    v-model="contact.email"
                  />
                
                </b-form-group>
          
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Address" v-slot="{ errors }">
                <b-form-group label="Address" label-for="h-Address">
                  <b-form-input
                    id="h-Address"
                    placeholder="Address"
                    v-model="contact.addressLine1"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Street Name" v-slot="{ errors }">
                <b-form-group label="Street Name" label-for="h-Street">
                  <b-form-input
                    id="h-Street"
                    placeholder="Street Name"
                    v-model="contact.addressLine2"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Tel" v-slot="{ errors }">
                <b-form-group label="Tel" label-for="h-tel">
                  <b-form-input id="h-tel" placeholder="Tel" type="number"  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="contact.tel" />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Job Title" v-slot="{ errors }">
                <b-form-group label="job Title" label-for="h-title">
                  <b-form-input
                    id="h-title"
                    placeholder="Job title"
                    v-model="contact.jobTitle"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-button type="submit" variant="primary" block :disabled="invalid">
                <b-spinner small v-if="loadingContact" />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Edit Contact -->
    <b-modal id="modal-edit" centered title="Edit Contact" hide-footer>
      <validation-observer ref="formContactEdit" #default="{ invalid }">
        <b-form @submit.prevent="editContact">
          <b-row>
            <!-- username -->
            <b-col cols="12">
              <!--Status-->
              <b-form-group label="Title" label-for="h-Status" label-cols-md="2" class="">
                <b-form-group label-for="h-Status" class="">
                  <b-form-select v-model="edit.title" :options="options" />
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Full Name" label-for="h-company">
                <validation-provider rules="required" name="Full Name" #default="{ errors }">
                  <b-form-input
                    id="h-full"
                    placeholder="Full Name"
                    v-model="edit.otherName"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- email -->
            <b-col cols="12">
           
                <b-form-group label="Email" label-for="d-email">
                  <b-form-input
                    id="d-email"
                    type="email"
                    placeholder="Email"
                    v-model="edit.email"
                  />
          
                </b-form-group>
           
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Address" v-slot="{ errors }">
                <b-form-group label="Address" label-for="h-Address">
                  <b-form-input
                    id="h-Address"
                    placeholder="Address"
                    v-model="edit.addressLine1"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Street Name" v-slot="{ errors }">
                <b-form-group label="Street Name" label-for="h-Street">
                  <b-form-input
                    id="h-Street"
                    placeholder="Street Name"
                    v-model="edit.addressLine2"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Tel" v-slot="{ errors }">
                <b-form-group label="Tel" label-for="h-tel">
                  <b-form-input id="h-tel" placeholder="Tel" type="number"  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="edit.tel" />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider rules="required" name="Job Title" v-slot="{ errors }">
                <b-form-group label="job Title" label-for="h-title">
                  <b-form-input
                    id="h-title"
                    placeholder="Job title"
                    v-model="edit.jobTitle"
                  />
                  <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-button type="submit" variant="primary" block :disabled="invalid">
                <b-spinner small v-if="loadingContact" />
                Edit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-col class="d-flex justify-content-end" cols="12">
      <b-button variant="primary" v-b-modal.modal-add>
        <span>Add Contact</span>
      </b-button>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
 
    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="contactsList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(otherName)="data">
          {{ data.item.title }} {{ data.item.otherName }}
        </template>

        <template #cell(isPrimary)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              :id="data.item.id"
              :name="data.item.otherName"
              v-model="data.item.isPrimary"
              @change="updatePrimary(data.item.id)"
            >
            </b-form-checkbox>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item @click="getData(data.item)" v-b-modal.modal-edit>
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeContact(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-alert
          variant="warning"
          show
          v-if="contactsList.length === 0"
          class="d-flex justify-content-center"
        >
          <h4 class="alert-heading">No contact persons yet</h4>
        </b-alert>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BAlert,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BCard,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";

import axios from "axios";
export default {
  components: {
    BTable,
    BAvatar,
    BAlert,
    BBadge,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BCard,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      token: localStorage.getItem("accessToken"),
      contact: {
        source: null,
        otherName: null,
        email: null,
        addressLine1: null,
        addressLine2: null,
        tel: null,
        jobTitle: null,
        isPrimary: false,
        isActive: true,
        comments: null,
      },
      options: [
        { value: null, text: "Please select a title" },
        { value: "Mr", text: "Mr" },
        { value: "Mrs", text: "Mrs" },
        { value: "Ms", text: "Ms" },
      ],
      selected: null,
      ///////////////

      edit: [],
      editPrimary: null,
      leadId: this.$route.params.id,
      required,
      email,
      id: this.$route.params.id,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "otherName",
          label: "Contact Name",
        },
        { key: "email", label: "Email", sortable: true },
        { key: "tel", label: "Tel", sortable: true },
        { key: "jobTitle", label: "Job Title" },
        { key: "isPrimary", label: "Primary", sortable: true },
        "Actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("leadModule", {
      contactsList: "contacts",

      loading: "loading",
    }),

    ...mapGetters("contactModule", {
      loadingContact: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    ...mapActions("leadModule", ["getLeadContactPersonAction"]),
    ...mapActions("contactModule", [
      "addContactsAction",
      "deleteLeadContact",
      "updateContactsAction",
      "makeContactPrimary",
    ]),

    getData(item) {
      this.edit = item;
      this.editPrimary = item.isPrimary;
    },

    addContact() {
      this.$refs.formContact.validate().then((success) => {
        if (success) {
          let payload = {
            leadId: this.id,
            title: this.selected,
            otherName: this.contact.otherName,
            email: this.contact.email,
            addressLine1: this.contact.addressLine1,
            addressLine2: this.contact.addressLine2,
            tel: this.contact.tel,
            jobTitle: this.contact.jobTitle,
            isPrimary: this.contact.isPrimary,
            isActive: this.contact.isActive,
            comments: this.contact.comments,
          };
          this.addContactsAction(payload).then(() => {
            this.$bvToast.toast("Contact added successfully", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.getLeadContactPersonAction(this.id);
            this.$bvModal.hide("modal-add");
            this.contact = {
              source: null,
              otherName: null,
              email: null,
              addressLine1: null,
              addressLine2: null,
              tel: null,
              jobTitle: null,
              isPrimary: false,
              isActive: true,
              comments: null,
            };
          });
        }
      });
    },
    editContact() {
      this.$refs.formContactEdit.validate().then((success) => {
        if (success) {
          let payload = {
            id: this.edit.id,
            leadId: this.id,
            title: this.edit.title,
            otherName: this.edit.otherName,
            email: this.edit.email,
            addressLine1: this.edit.addressLine1,
            addressLine2: this.edit.addressLine2,
            tel: this.edit.tel,
            jobTitle: this.edit.jobTitle,
            isPrimary: this.editPrimary,
            isActive: this.edit.isActive,
            comments: this.edit.comments,
          };
          this.updateContactsAction(payload)
            .then(() => {
              this.$bvToast.toast("Contact updated successfully", {
                title: "Success",
                variant: "success",
                solid: true,
              });

              this.getLeadContactPersonAction(this.id);
              this.$bvModal.hide("modal-edit");
            })
            .catch((response) => {
              this.$bvToast.toast(response, {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    removeContact(id) {
      let payload = {
        leadId: this.id,
        contactPersonId: id,
      };

      console.log(payload);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteContact(payload);
          
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Contact is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    updatePrimary(id) {
      let payload = {
        leadId: this.leadId,
        contactPersonId: id,
      };

      this.makeContactPrimary(payload).then(() => {
        this.$bvToast.toast("Contact updated successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.getLeadContactPersonAction(this.id);
      });
    },

    deleteContact(payload) {
      let tenant = localStorage.getItem("userTenant");
      let headers = {
        "Content-Type": "application/json",
        tenant: tenant,
      };

      axios.defaults.headers.Authorization = "Bearer " + this.token;
      //
      const url = process.env.VUE_APP_API_URLv1
      axios
        .delete(
          url + "contactpersons/deleteleadcontactperson",
          { headers, data: payload }
        )
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Contact has been deleted",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.getLeadContactPersonAction(this.id);
        })
        .catch((error) => {
          this.$swal({
            title: "Delete Failed",
            text: "Error while deleting contact",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.getLeadContactPersonAction(this.id);
        });
    },

    saveSocialMedia(text) {
      this.selected = text;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  async mounted() {
    await this.getLeadContactPersonAction(this.id)
      .then(() => {
        this.totalRows = this.contactsList.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
