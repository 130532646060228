<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex justify-content-end my-1">
        <b-button variant="primary" @click="sendQuote()">
          <span class="text-nowrap">Add Quotation</span>
        </b-button>
      </div>
    </b-col>
    <!-- search input -->
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
 
    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="quotation"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      <template v-slot:cell(createdOn)="data">
          {{ convertTimestampToDate(data.item.createdOn) }}
        </template>
        <template v-slot:cell(dueDate)="data">
          {{ convertTimestampToDate(data.item.dueDate) }}
        </template>
      <template #cell(total)="data">
        Rs {{data.item.total.toFixed(2)}}
        </template>
     
     
        <template #cell(actions)="data">

          <b-button :to="getQuotationEdit(data.item.id)" variant="info">
            <feather-icon icon="EyeIcon" size="16" class="text-body align-middle mr-25" />   
            View
          </b-button>
          
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-alert
          variant="warning"
          show
          v-if="quotation.length === 0"
          class="d-flex justify-content-center"
        >
          <h4 class="alert-heading">No Quotations yet</h4>
        </b-alert>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BAlert,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BAlert,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      id: this.$route.params.id,
      leadClientName: "",
      total: "",
      dueDate: "",
      isActive: 1,
      status: "",
      //salesOrderData: [],
      quotationData: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "total", label: "TOTAL" },
        { key: "createdOn", label: "CREATED ON" },
        { key: "dueDate", label: "DUE DATE" },
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("salesOrderModule", {
      salesOrderList: "salesorders",
      loading: "loading",
    }),

    ...mapGetters("customerModule", {
      quotation: "quotation",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.quotationList.length;
  },
  methods: {
    sendQuote() {
      this.$router.push( "/quotation/add/customer/" + this.$route.params.id );
    },
    getsalesOrderData(data) {
      console.log(data);
      this.quotationData = data;
    },
    getQuotationEdit(item) {
      return "/quotation/preview/" + item;
    },
    convertTimestampToDate(timestamp) {
      return new Date(timestamp).toLocaleDateString();
    },

    removesalesOrder(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeSalesOrdersAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your file is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ...mapActions("salesOrderModule", [
      "getSalesOrdersListAction",
      "removeSalesOrdersAction",
    ]),
    ...mapActions("customerModule", ["getQuotationsByIDAction"]),
  },
  async mounted() {
    await this.getQuotationsByIDAction(this.id)
      .then(() => {
        this.totalRows = this.quotationList.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
