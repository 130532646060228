<template>
  <div>
    <validation-observer
      ref="formIndividual"
      @change="submitForm"
      v-if="individualCheck"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="addLeadsIndividual">
        <b-row>
          <!-- username -->
          <b-col cols="12">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Customer Source"
              vid="Customer Source"
            >
              <b-form-group label="Customer Source" label-for="h-First">
                <b-form-select v-model="custId.source" :options="socialMedias" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- username -->

          <b-col cols="12">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Sales Person"
              vid="Sales Person"
            >
              <b-form-group label="Sales Person" label-for="h-First">
                <b-form-select
                  v-model="custId.salesPersonId"
                  :options="optionsSalesPerson"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Title"
              vid="Title"
            >
              <b-form-group label="Title" label-for="h-First">
                <b-form-select v-model="custId.title" :options="options" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Full Name" label-for="h-company">
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="Full Name"
                vid="Full Name"
              >
                <b-form-input
                  id="h-full"
                  placeholder="Full Name"
                  v-model="custId.otherName"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- email -->
          <b-col cols="12">
            <validation-provider
              rules="required|email"
              v-slot="{ errors }"
              name="Email"
              vid="Email"
            >
              <b-form-group label="Email" label-for="d-email">
                <b-form-input
                  id="d-email"
                  type="email"
                  placeholder="Email"
                  v-model="custId.email"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Address"
              vid="Address"
            >
              <b-form-group label="Address" label-for="h-Address">
                <b-form-input
                  id="h-Address"
                  placeholder="Address"
                  v-model="custId.addressLine1"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Street Name"
              vid="Street Name"
            >
              <b-form-group label="Street Name" label-for="h-Street">
                <b-form-input
                  id="h-Street"
                  placeholder="Street Name"
                  v-model="custId.addressLine2"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="City"
              vid="City"
            >
              <b-form-group label="City" label-for="h-City">
                <b-form-input id="h-City" placeholder="City" v-model="custId.city" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Tel"
              vid="Tel"
            >
              <b-form-group label="Tel" label-for="h-tel">
                <b-form-input id="h-tel" placeholder="Tel" type="number" onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="custId.tel" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Job Title" label-for="h-title">
              <b-form-input
                id="h-title"
                placeholder="Job title"
                v-model="custId.jobTitle"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Products interested in" label-for="h-title">
              <b-form-textarea
                id="event-description"
                placeholder="Products interested in"
                v-model="custId.comments"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              <b-spinner small v-if="loading" />
              Submit
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <validation-observer ref="formCompany" v-if="!individualCheck" @change="submitForm">
      <b-form @submit.prevent="addLeadsCompany">
        <b-row>
          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Lead Source"
              vid="Lead Source"
            >
              <b-form-group label="Lead Source" label-for="h-First">
                <b-form-select v-model="custId.source" :options="socialMedias" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Sales Person"
              vid="Sales Person"
            >
              <b-form-group label="Sales Person" label-for="h-First">
                <b-form-select
                  v-model="custId.salesPersonId"
                  :options="optionsSalesPerson"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Company Name" label-for="h-company">
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="Company Name"
                vid="Company Name"
              >
                <b-form-input
                  id="h-full"
                  placeholder="Company Name"
                  v-model="custId.otherName"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- email -->
          <b-col cols="12">
            <validation-provider
              rules="required|email"
              v-slot="{ errors }"
              name="email"
              vid="email"
            >
              <b-form-group label="Email" label-for="d-email">
                <b-form-input
                  id="d-email"
                  type="email"
                  placeholder="Email"
                  v-model="custId.email"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Address"
              vid="Address"
            >
              <b-form-group label="Address" label-for="h-Address">
                <b-form-input
                  id="h-Address"
                  placeholder="Address"
                  v-model="custId.addressLine1"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Street Name"
              vid="Street Name"
            >
              <b-form-group label="Street Name" label-for="h-Street">
                <b-form-input
                  id="h-Street"
                  placeholder="Street Name"
                  v-model="custId.addressLine2"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="City"
              vid="City"
            >
              <b-form-group label="City" label-for="h-City">
                <b-form-input id="h-City" placeholder="City" v-model="custId.city" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Company Tel"
              vid="Company Tel"
            >
              <b-form-group label="Company Tel" label-for="h-tel">
                <b-form-input id="h-tel" placeholder="Company Tel" type="number" onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'" v-model="custId.tel" />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Website" label-for="h-Website">
              <b-form-input
                id="h-Website"
                placeholder="Website"
                v-model="custId.website"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Vat Number" label-for="h-Number">
              <b-form-input
                id="h-Number"
                placeholder="Vat Number"
                v-model="custId.vatNumber"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="Company BRN"
              vid="Company BRN"
            >
              <b-form-group label="Company BRN" label-for="h-Number">
                <b-form-input
                  id="h-Number"
                  placeholder="Company BRN"
                  v-model="custId.brn"
                />
                <small v-if="errors.length" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Products interested in" label-for="h-title">
              <b-form-textarea
                id="event-description"
                placeholder="Products interested in"
                v-model="custId.comments"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
             
              variant="outline-secondary"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormDatalist,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsSalesPerson: [],
      id: this.$route.params.id,
      options: [
        { value: null, text: "Please select a title" },
        { value: "Mr", text: "Mr" },
        { value: "Mrs", text: "Mrs" },
        { value: "Ms", text: "Ms" },
      ],
      socialMedias: [
        { text: "Other", value: 0 },
        { text: "Facebook", value: 1 },
        { text: "Instagram", value: 2 },
        { text: "Twitter", value: 3 },
        { text: "LinkedIn", value: 4 },
      ],
      selected: "Select Social Media",
      ///////////////
      required,
      email,
      individualCheck: false,
    };
  },

  computed: {
    ...mapGetters("customerModule", {
      custId: "custId",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },
  mounted() {},
  async mounted() {
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
    await this.getCustomersByIDAction(this.id).then((res) => {
      if (this.custId.typeDesc === "INDIVIDUAL") {
        this.individualCheck = true;
        this.selected = this.custId.sourceDesc;
      } else {
        this.individualCheck = false;
      }
    });
  },
  methods: {
    ...mapActions("customerModule", ["getCustomersByIDAction", "updateCustomersAction"]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    saveSocialMedia(value, text) {
      this.custId.source = value;
      this.selected = text;
    },

    addLeadsIndividual() {
      this.$refs.formIndividual.validate().then((success) => {
        if (success) {
          this.$swal({
            title: "Updating Customer",
            text: "Please wait while the customer is being updated...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading();
              let payload = {
                id: this.custId.id,
                otherName: this.custId.otherName,
                salesPersonId: this.custId.salesPersonId,
                email: this.custId.email,
                addressLine1: this.custId.addressLine1,
                addressLine2: this.custId.addressLine2,
                city: this.custId.city,
                tel: this.custId.tel,
                title: this.custId.title,
                jobTitle: this.custId.jobTitle,
                source: this.custId.source,
                comments: this.custId.comments,
              };
              this.updateCustomersAction(payload)
                .then(() => {
                  this.$swal.hideLoading();
                  this.success();
                })
                .catch(() => {
                  this.$swal.hideLoading();
                  this.error();
                });
            },
          });
        }
      });
    },

    addLeadsCompany() {
      let payload = {
        id: this.custId.id,
        otherName: this.custId.otherName,
        email: this.custId.email,
        companyName: this.custId.companyName,
        addressLine1: this.custId.addressLine1,
        addressLine2: this.custId.addressLine2,
        city: this.custId.city,
        tel: this.custId.tel,
        website: this.custId.website,
        vatNumber: this.custId.vatNumber,
        source: this.custId.source,
        comments: this.custId.comments,
        brn: this.custId.brn,
        type: 1,
        status: 0,
        isActive: true,
        salesPersonId: this.custId.salesPersonId,
      };

      this.$swal({
        title: "Updating Customer",
        text: "Please wait while the Customer is being updated...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
          this.updateCustomersAction(payload)
            .then(() => {
              this.$swal.hideLoading();
              this.success();
            })
            .catch(() => {
              this.$swal.hideLoading();
              this.error();
            });
        },
      });
    },

    success() {
      this.$swal({
        title: "Customer!",
        text: "You have successfully updated the Customer!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    // error
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    resetForm() {
      this.$swal({
        title: "Resetting Form",
        text: "Please wait while the form is being reset...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
          this.getCustomersByIDAction(this.id)
            .then((res) => {
              this.$swal.hideLoading();
              if (this.custId.typeDesc === "INDIVIDUAL") {
                this.individualCheck = true;
              } else {
                this.individualCheck = false;
              }
              this.$swal({
                title: "Success",
                text: "The form has been reset.",
                icon: "success",
              });
            })
            .catch((error) => {
              this.$swal.hideLoading();
              this.$swal({
                title: "Error",
                text: `Error resetting form: ${error.message}`,
                icon: "error",
              });
            });
        },
      });
    },

    submitForm() {
      // emit the data to another page
      this.custId.companyName = this.custId.type == 1 ? this.custId.otherName : null;
      this.$emit("onchange", this.individual);
    },
  },
};
</script>
