<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
 
    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="invoiceList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(dueDate)="data">
          {{ convertTimestampToDate(data.item.dueDate) }}
        </template>

        <template v-slot:cell(createdOn)="data">
          {{ convertTimestampToDate(data.item.createdOn) }}
        </template>

        <template v-slot:cell(total)="data">
          Rs {{data.item.total.toFixed(2)}}
        </template>


        <template v-slot:cell(actions)="data">
          <!-- redirect to ViewInvoice.vue -->
          <b-button variant="primary" :to="getInvoiceId(data.item.id)">
            <feather-icon icon="EyeIcon" class="mr-50" /> View
          </b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-alert
          variant="warning"
          show
          v-if="invoiceList.length === 0"
          class="d-flex justify-content-center"
        >
          <h4 class="alert-heading">No Invoices yet</h4>
        </b-alert>
      </div>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BAlert,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BAlert,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BSpinner,

    ///
    required,
  },
  data() {
    return {
      isActive: false,
      id: this.$route.params.id,
      labourName: "",
      quantity: "",
      costPerHour: "",
      perPage: 5,
      invoiceData: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "invoiceNo",
        "total",
        "createdOn",
        "dueDate",
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("customerModule", {
      invoiceList: "invoice",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.invoiceList.length;
  },
  methods: {
    convertTimestampToDate(timestamp) {
      return new Date(timestamp).toLocaleDateString();
    },
    getInvoiceData(data) {
      this.labourData = data;
    },
    ...mapActions("customerModule", ["getInvoicesByIDAction"]),
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getInvoiceId(item) {
      return "/invoice/view/" + item;
    },
  },
  async mounted() {
    await this.getInvoicesByIDAction(this.id)
      .then(() => {
        console.log(this.invoiceList)
        this.totalRows = this.invoiceList.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
