<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div v-if="!loadingSpinner">
      <b-tabs>
        <div class="d-flex justify-content-center mb-1">
          <b-spinner
            variant="success"
            label="Spinning"
            block
            v-if="loading"
            style="width: 3rem; height: 3rem"
          ></b-spinner>
        </div>

        <b-tab title="Contacts" active>
          <ContactList />
        </b-tab>
        <b-tab title="Customer Info">
          <b-card>
            <CustomerInfo />
          </b-card>
        </b-tab>
        <b-tab title="Quotation">
          <b-card>
            <Quotation />
          </b-card>
        </b-tab>
        <b-tab title="Proforma">
          <b-card>
            <SaleOrder />
          </b-card>
        </b-tab>
        <b-tab title="Invoices">
          <b-card>
            <invoice />
          </b-card>
        </b-tab>
        <b-tab title="Note">
          <ToDo />
        </b-tab>
        <b-tab title="File Management">
          <FileMgt />
        </b-tab>
         <!-- <b-tab title="Complaint">
         
        </b-tab> -->
           <!-- <b-tab title="Opportunities">
         
        </b-tab> -->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import ContactList from "./ContactList.vue";
import CustomerInfo from "./CustomerInfo.vue";
import Quotation from "./Quotation.vue";
import SaleOrder from "./SaleOrder.vue";
import invoice from "./invoice.vue";
import FileMgt from "./FileMgt.vue";
import ToDo from "./ToDo.vue";
import { BTabs, BTab, BSpinner, BCardText, BCard, BButton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BTabs,
    BCardText,
    BCard,
    BTab,
    BButton,
    BSpinner,
    ContactList,
    invoice,
    CustomerInfo,
    Quotation,
    SaleOrder,
    invoice,
    FileMgt,
    ToDo,
  },

  data() {
    return {
      loadingSpinner: true,
      id: this.$route.params.id,
    };
  },

  mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);
  },
};
</script>
